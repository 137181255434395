import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout/Layout"
import SiteIntro from "../components/site-intro/SiteIntro"

export const query = graphql`
  {
    prismic {
      report_home(lang: "en-gb", uid: "home") {
        headline
        blurb
      }
    }
  }
`

export default function IndexPage(props) {
  const data = props.data.prismic.report_home

  return (
    <Layout>
      <SiteIntro title={data.headline[0].text} blurb={data.blurb[0].text} />
    </Layout>
  )
}
