import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import { TimelineMax } from "gsap"
import { add } from "gsap-tools"
import { media } from "../../style/breakpoints"
import { navigate } from "gatsby"
import Div100vh from "react-div-100vh"

import Hand from "../../images/icons/hand.svg"
import Audio from "../../images/icons/audio.svg"

import Comma from "../comma/Comma"

const SiteIntro = ({ title, blurb, openMenu }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const _slideBackround = useRef()
  const _copy = useRef()
  const _CTA = useRef()
  const _hand = useRef()
  const _title = useRef()
  const _commas = useRef()
  const _audio = useRef()

  const tl = new TimelineMax({ paused: false, id: "myTimeline" })

  useEffect(() => {
    setIsLoaded(true)
    tl.fromTo(
      _commas.current,
      1.5,
      { opacity: 0 },
      {
        opacity: 1,
      }
    )
      .to(
        _slideBackround.current,
        0.8,
        {
          left: "-25vw",
        },
        "-=0"
      )
      .fromTo(
        _hand.current,
        1,
        { translateY: "100%", translateX: "-50%", opacity: 0 },
        {
          translateY: "2%",
          translateX: "-50%",
          opacity: 1,
        },
        "-=2"
      )
      .fromTo(
        _title.current,
        1,
        { opacity: 0, translateY: "50%" },
        {
          opacity: 1,
          translateY: "0%",
        },
        "-=0.5"
      )
      .fromTo(
        _hand.current,
        1,
        { translateY: "2%", translateX: "-50%" },
        {
          translateY: "100%",
          translateX: "-50%",
        },
        "+=1.5"
      )
      .fromTo(
        _copy.current,
        1,
        { opacity: 0, translateY: "50%" },
        {
          opacity: 1,
          translateY: "0%",
        },
        "-=2.5"
      )
      .fromTo(
        _CTA.current,
        1,
        { opacity: 0, translateY: "50%" },
        {
          opacity: 1,
          translateY: "0%",
        },
        "-=0.5"
      )
      .fromTo(
        _audio.current,
        1,
        { opacity: 0 },
        {
          opacity: 1,
        },
        "-=0.5"
      )
    return () => {}
  }, [])

  let commas = []
  for (var i = 0; i < 6; i++) {
    commas.push(<CommaComp key={i} />)
  }

  return (
    <Wrapper>
      <ColorSlide ref={_slideBackround} />
      <HandComp ref={_hand}>
        <Hand />
      </HandComp>
      <CommasWrapper ref={_commas}> {commas}</CommasWrapper>

      <Inner>
        <Title ref={_title}>{title}</Title>
        <Blurb ref={_copy}>{blurb}</Blurb>
        <CTAWrapper ref={_CTA}>
          <CTA
            onClick={() => {
              openMenu()
            }}
          >
            <span>Hear the stories</span>
          </CTA>
          <CTA
            ref={_CTA}
            onClick={() => {
              navigate("/see-me")
            }}
          >
            <span>Learn about the Movement</span>
          </CTA>
        </CTAWrapper>
      </Inner>
      <AudioComp ref={_audio}>
        <Audio />
        <span>For an optimal experience turn your sound on.</span>
      </AudioComp>
    </Wrapper>
  )
}

const Wrapper = styled(Div100vh)`
  height: 100vh;
  background: ${props => props.theme.colors.white};
  overflow-y: scroll;
`

const ColorSlide = styled.div`
  background: ${props => props.theme.colors.blue};
  position: fixed;
  top: 0;
  left: -200vw;
  right: auto;
  bottom: 0;
  width: 150vw;
  transform: skewX(-20deg);
  ${media.largest} {
    transform: skewX(-20deg) scale(1.5);
    left: -250vw;
  }
`

const Inner = styled.div`
  position: relative;
  z-index: 3;
  margin-top: 250px;
  padding-bottom: 200px;
  @supports (display: grid) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    padding-bottom: 0px;
  }
  @media (max-height: 600px) {
    justify-content: flex-start;
    margin-top: 130px;
  }
`

const Title = styled.h1`
  ${props => props.theme.typography.h3};
  max-width: ${props => props.theme.grid.desktop.col(6)};
  text-align: center;
  margin: 0 auto;
  color: ${props => props.theme.colors.white};
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;

  ${media.largest} {
    max-width: ${props => props.theme.grid.mobile.col(3)};
  }

  ${media.medium} {
    max-width: ${props => props.theme.grid.mobile.col(4)};
  }
`

const Blurb = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.white};
  text-align: center;
  width: ${props => props.theme.grid.desktop.col(4)};
  margin: ${props => props.theme.sizing.m} auto ${props => props.theme.sizing.m};
  opacity: 0;
  ${media.largest} {
    width: 100%;
    max-width: ${props => props.theme.grid.mobile.col(2)};
  }

  ${media.medium} {
    max-width: ${props => props.theme.grid.mobile.col(4)};
  }
`

const CTA = styled.div`
  margin: 0 auto;
  padding-bottom: ${props => props.theme.sizing.xs};
  cursor: pointer;
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.colors.white};
    opacity: 0.5;
    ${props => props.theme.transitions.default("all")};
  }
  span {
    ${props => props.theme.typography.cta};
    color: ${props => props.theme.colors.white};
  }

  ${media.hover} {
    &:hover {
      &:before {
        opacity: 1;
        bottom: 4px;
      }
    }
  }
`

const CTAWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  @supports (display: grid) {
    display: flex;
    justify-content: space-evenly;
  }
  > div {
    margin: 0 ${props => props.theme.sizing.xs};
  }
  ${media.largest} {
    flex-direction: column;

    > div {
      margin: 0 0 25px 0;
    }
  }
`

const HandComp = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 15vh;
  opacity: 0;
  z-index: 2;
  svg {
    height: auto;
  }
`

const CommaComp = styled(Comma)`
  position: fixed;
`

const CommasWrapper = styled.div`
  opacity: 0;
  > svg:nth-child(1) {
    left: ${props => props.theme.grid.desktop.col(1)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
    path {
      fill: ${props => props.theme.colors.green};
    }
  }
  > svg:nth-child(2) {
    left: ${props => props.theme.grid.desktop.col(3)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
    path {
      fill: ${props => props.theme.colors.pink};
    }
  }

  > svg:nth-child(3) {
    left: ${props => props.theme.grid.desktop.col(5)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
    path {
      fill: ${props => props.theme.colors.orange};
    }
  }
  > svg:nth-child(4) {
    left: ${props => props.theme.grid.desktop.col(7)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
    path {
      fill: ${props => props.theme.colors.red};
    }
  }
  > svg:nth-child(5) {
    left: ${props => props.theme.grid.desktop.col(9)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
    path {
      fill: #5bd1dd;
    }
  }
  > svg:nth-child(6) {
    left: ${props => props.theme.grid.desktop.col(11)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}vh;
  }

  > svg {
    width: 25px;
  }
`

const AudioComp = styled.div`
  position: absolute;
  bottom: ${props => props.theme.sizing.m};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  opacity: 0;
  span {
    ${props => props.theme.typography.body};
    font-size: 13px;
    font-style: italic;
    color: ${props => props.theme.colors.white};
  }

  svg {
    margin: 0 auto;
  }
  ${media.largest} {
    display: none;
  }
`

export default React.memo(SiteIntro)
